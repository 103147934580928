import "jquery";
import "bootstrap";
import "bootstrap-slider";
import "datatables.net-responsive/js/dataTables.responsive";
import "datatables.net-buttons/js/dataTables.buttons";
// import "datatables.net-fixedheader/js/dataTables.fixedHeader";
import "../config/dataTables.fixedHeader";
import "datatables.net";
import "datatables.net-buttons/js/buttons.colVis";
import "froala-editor/js/froala_editor.pkgd.min";
import "messenger-hubspot/build/js/messenger.min";
import "select2";
// import "widgster";

if ("production" === ENV) {
  // Production

} else {
  // Development

}
